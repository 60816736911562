import type { CustomEntitySchema } from 'mosaiq/custom-entities';
import { Context, ReactNode, createContext, useContext } from 'react';

import { AsyncDispatch, AsyncReducer, useAsyncReducer } from 'utils/hooks';

import { cacheMetadata, fetchMetadata, readMetadataFromCache, updatePersonFlag } from './api';
import { LoginState, Session, SessionData, SessionOrg, SessionPerson } from './types';

export enum MetadataActionType {
  FETCH = 'fetchMetadata',
  SET = 'setMetadata',
  UPDATE_PERSON = 'updatePerson',
  UPDATE_ORG = 'updateOrg',
  UPDATE_CSRF_TOKEN = 'updateCsrfToken',
  SET_TYPES = 'setTypes',
}

type MetadataStateAction<L extends LoginState = 'authed'> =
  | {
      type: MetadataActionType.FETCH | 'fetchMetadata';
      metadata?: never;
      org?: never;
      person?: never;
      entity_types?: never;
    }
  | {
      type: MetadataActionType.SET | 'setMetadata';
      metadata: Session<L>;
      org?: never;
      person?: never;
      entity_types?: never;
    }
  | {
      type: MetadataActionType.UPDATE_PERSON | 'updatePerson';
      metadata?: never;
      org?: never;
      person: Partial<SessionPerson>;
      entity_types?: never;
    }
  | {
      type: MetadataActionType.UPDATE_ORG | 'updateOrg';
      metadata?: never;
      org: Partial<SessionOrg>;
      person?: never;
      entity_types?: never;
    }
  | {
      type: MetadataActionType.SET_TYPES | 'setTypes';
      metadata?: never;
      org?: never;
      person?: never;
      entity_types: CustomEntitySchema[];
    };

const reducer = <L extends LoginState>(state: Session<L>, action: MetadataStateAction<L>) => {
  let newState: Session;

  switch (action.type) {
    case MetadataActionType.FETCH:
      return fetchMetadata();

    case MetadataActionType.SET:
      const { metadata } = action;
      cacheMetadata(metadata);
      return metadata;

    case MetadataActionType.UPDATE_PERSON:
      const { person } = action;
      newState = {
        ...state,
        person: {
          ...state.person,
          ...person,
        },
      } as SessionData;
      cacheMetadata(newState);
      return newState;

    case MetadataActionType.UPDATE_ORG:
      const { org } = action;
      newState = {
        ...state,
        org: {
          ...state.org,
          ...org,
        },
      } as SessionData;

      cacheMetadata(newState);
      return newState;

    case MetadataActionType.SET_TYPES:
      const { entity_types } = action;
      newState = {
        ...state,
        entity_types,
      } as SessionData;
      cacheMetadata(newState);
      return newState;

    default:
      return state;
  }
};

type MetadataContextType<L extends LoginState> = [
  Session<L>,
  AsyncDispatch<Session<L>, MetadataStateAction<L>>,
  typeof MetadataActionType,
];

const MetadataContext = createContext<MetadataContextType<'authed'>>([] as any);

// Keep the store as an object so that we can update the Context outside of React components.
// This is only fine because this Context is only meant to be used once (global state).
const store: {
  state?: SessionData;
  dispatch?: AsyncDispatch<SessionData, MetadataStateAction<LoginState>>;
} = {
  state: undefined,
  dispatch: undefined,
};

const MetadataProvider = <L extends LoginState = 'authed'>({
  children,
}: {
  children: ReactNode;
}) => {
  const Provider = (MetadataContext as unknown as Context<MetadataContextType<L>>).Provider;
  const [state, dispatch] = useAsyncReducer<Session<L>, MetadataStateAction<L>>(
    reducer as unknown as AsyncReducer<Session<L>, MetadataStateAction<L>>,
    () => readMetadataFromCache() || ({} as Session<L>),
  );

  store.state = state as SessionData;
  store.dispatch = dispatch as AsyncDispatch<SessionData, MetadataStateAction<LoginState>>;

  return <Provider value={[state, dispatch, MetadataActionType]}>{children}</Provider>;
};

const useMetadataState = <L extends LoginState = 'authed'>() =>
  useContext(MetadataContext) as MetadataContextType<L>;

const useIsGuestApp = () => {
  const [{ person }] = useMetadataState();

  return person?.org_role === 'guest';
};

const usePersonFlagsState = () => {
  const [{ person }] = useMetadataState();

  return person?.flags ?? {};
};

const usePersonFlagUpdate = () => {
  const [_, dispatchMetadata] = useMetadataState();

  return async (personFlag: string, value: boolean) =>
    updatePersonFlag(personFlag, value).then(({ data: updatedPerson }) => {
      dispatchMetadata({ type: 'updatePerson', person: updatedPerson });
    });
};

// Updates the CSRF token from outside React components
const updateMetadataCsrfToken = (csrfToken: string) => {
  store.dispatch?.({
    type: MetadataActionType.SET,
    metadata: {
      ...store.state!,
      csrf_token: csrfToken,
    },
  });
};

const DISPLAY_NAME = 'MetadataState';

MetadataContext.displayName = DISPLAY_NAME;

MetadataProvider.displayName = DISPLAY_NAME;

export {
  MetadataContext,
  MetadataProvider,
  updateMetadataCsrfToken,
  useIsGuestApp,
  useMetadataState,
  usePersonFlagUpdate,
  usePersonFlagsState,
};
