import axios from 'axios';

import config from 'config';
import { setAxiosCsrfHeaders } from './setAxiosCsrfHeaders';
import { updateMetadataCsrfToken } from 'store/metadata';

export async function csrfRefresh(): Promise<string> {
  const {
    data: { csrf: csrfToken },
  } = await axios.get(config.envConfig.endpoints.auth.csrf);

  setAxiosCsrfHeaders(csrfToken);
  updateMetadataCsrfToken(csrfToken);

  return csrfToken;
}
