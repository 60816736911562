import axios from 'axios';

import { StaticSessionData } from 'store/metadata';

export function setAxiosCsrfHeaders(csrfToken?: StaticSessionData['csrf_token']) {
  if (csrfToken) {
    axios.defaults.headers.delete['X-Qatalog-Csrf-Token'] =
      axios.defaults.headers.get['X-Qatalog-Csrf-Token'] =
      axios.defaults.headers.post['X-Qatalog-Csrf-Token'] =
      axios.defaults.headers.put['X-Qatalog-Csrf-Token'] =
      axios.defaults.headers.patch['X-Qatalog-Csrf-Token'] =
        csrfToken;
  } else {
    delete axios.defaults.headers.delete['X-Qatalog-Csrf-Token'];
    delete axios.defaults.headers.get['X-Qatalog-Csrf-Token'];
    delete axios.defaults.headers.post['X-Qatalog-Csrf-Token'];
    delete axios.defaults.headers.put['X-Qatalog-Csrf-Token'];
    delete axios.defaults.headers.patch['X-Qatalog-Csrf-Token'];
  }
}
